// @TODO hardcoded auth/users for protected portal clients
export function protectedClientIds() {
  // CES Foundation, Schooled
  return [2460, 2459]
}

export function adminIdWhitelistForProtectedClients() {
  // Jono, Vicky
  return [62, 101]
}

export function getUser(state) {
  return state.user
}

export function isFileLord(_state, getters) {
  return !!getters.getUser?.is_file_lord
}

export function isAdmin(_state, getters) {
  return !!getters.getUser?.admin_user
}

export function isSuperAdmin(_state, getters) {
  return !!getters.getUser?.super_admin_user
}
