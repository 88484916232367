<template>
  <div class="w-full lg:px-8">
    <AlertToast />

    <!-- Header -->
    <div
      class="w-full max-w-7xl mx-auto px-6 lg:px-0 flex justify-between lg:h-[10vh] text-white"
    >
      <div class="flex-1 flex items-center justify-end gap-x-8">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.schooled.nz/contact-us/"
          class="brand__link--white group"
        >
          <svg-icon
            icon-name="email"
            aria-hidden="true"
            class="w-8 h-8 sm:w-6 sm:h-6 flex sm:hidden group-hover:text-secondary"
          />
          <span class="hidden sm:flex group-hover:text-secondary">Contact</span>
        </a>

        <button type="button" class="brand__button--trans-secondary relative">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.schooled.nz/"
            class="absolute w-full h-full"
          />
          <svg-icon
            icon-name="home"
            aria-hidden="true"
            class="w-8 h-8 sm:w-6 sm:h-6 flex sm:hidden"
          />
          <span class="hidden sm:flex">Schooled Website</span>
        </button>

        <button
          v-if="settings?.client_website_url"
          type="button"
          class="brand__button--trans-secondary relative"
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            :href="settings?.client_website_url"
            class="absolute w-full h-full"
          />
          <svg-icon
            icon-name="home"
            aria-hidden="true"
            class="w-8 h-8 sm:w-6 sm:h-6 flex sm:hidden"
          />
          <span class="hidden sm:flex">{{ selectedSchool.name }} Website</span>
        </button>

        <button
          v-if="isSuperAdmin"
          type="button"
          class="brand__button--secondary"
          @click="routeToAdminArea"
        >
          <span class="hidden sm:flex">Admin Area</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-4 h-4"
          >
            <path
              d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z"
            />
            <path
              d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z"
            />
          </svg>
        </button>
      </div>
    </div>
    <!-- /Header -->

    <!-- App view -->
    <div
      class="bg-white w-full max-w-7xl mx-auto lg:h-[85vh] lg:rounded-3xl overflow-hidden lg:flex"
    >
      <!--
        <MobileNav
        class="flex lg:hidden"
        /> 
      -->
      <SideNav class="hidden lg:flex z-80" />
      <!-- View content -->
      <router-view class="w-full overflow-y-scroll z-10" />
    </div>
    <!-- /App view -->

    <PageFooter />
  </div>
</template>

<script>
import { defineComponent, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import AlertToast from '../../components/AlertToast.vue'
import SideNav from '../../components/SideNav.vue'
import PageFooter from '../../components/PageFooter.vue'

export default defineComponent({
  components: {
    AlertToast,
    SideNav,
    PageFooter,
  },

  setup() {
    const route = useRoute()
    const store = useStore()
    const isSuperAdmin = computed(() => store.getters['auth/isSuperAdmin'])
    const settings = computed(() => store.getters['portal/getSettings'])
    const selectedSchool = computed(() => {
      return store.getters['portal/getSchool'] || null
    })

    const routeToAdminArea = () => {
      const currentUrl = window.location.href
      let parts = currentUrl.split('/')
      if (parts.length > 4 && parts[3] === 'portal') {
        parts.splice(3, 1) // Remove the 'portal' part
        parts = parts.slice(0, 4) // Keep only the first 4 parts after removal
        parts.splice(3, 0, 'admin-panel/resources/clients')
      }

      window.location.href = parts.join('/')
    }

    /**
     * Always re-get settings! Because several routes/pages
     * require them and this is the most reliable way to keep in sync.
     */
    const dispatchRouteActions = async () => {
      const actions = ['portal/dispatchGetSettings']
      if (route?.meta?.actions) {
        actions.push(...route.meta.actions)
      }

      store.dispatch('global/dispatchUpdateIsLoading', true)
      await Promise.all(actions.map((action) => store.dispatch(action)))
      store.dispatch('global/dispatchUpdateIsLoading')
    }

    watch(() => [route.name, route.params], dispatchRouteActions)

    onMounted(() => dispatchRouteActions())

    return {
      isSuperAdmin,
      routeToAdminArea,
      settings,
      selectedSchool,
    }
  },
})
</script>
