import { createWebHistory, createRouter, START_LOCATION } from 'vue-router'
import store from '../store/index.js'
import Welcome from './routes/Welcome.vue'
import SignIn from './routes/SignIn.vue'
import ResetPasswordValidation from './routes/ResetPasswordValidation.vue'
import Portal from './routes/Portal.vue'
import PortalBoardReports from './routes/PortalBoardReports.vue'
import PortalBoardMeetings from './routes/PortalBoardMeetings.vue'
import PortalDashboard from './routes/PortalDashboard.vue'
import PortalBoardResources from './routes/PortalBoardResources.vue'
import PortalResources from './routes/PortalResources.vue'
import PortalHelp from './routes/PortalHelp.vue'
import PortalSettings from './routes/PortalSettings.vue'

const getSchool = (to) => {
  const schoolId = Number.parseInt(to.params.id) || null
  const userSchools = store.getters['portal/getSchools']
  const defaultSchool = userSchools?.[0]

  const school = schoolId
    ? userSchools.find((school) => school.id === schoolId) || defaultSchool
    : defaultSchool
  return school
}

const mapRouteDisplayOrder = (item, index) => {
  if (item.meta?.navConfig) {
    item = {
      ...item,
      meta: {
        ...item.meta,
        navConfig: {
          ...item.meta.navConfig,
          displayOrder: index,
        },
      },
    }
  }

  return item
}

const routesPortalChildren = [
  {
    path: '',
    name: 'dashboard',
    component: PortalDashboard,
    props: true,
    meta: {
      navConfig: {
        name: 'Overview',
        icon: 'dashboard',
      },
      actions: ['portal/dispatchGetMeetings'],
    },
  },
  // {
  //   path: 'board-reports',
  //   name: 'boardReports',
  //   component: PortalBoardReports,
  //   props: true,
  //   meta: {
  //     navConfig: {
  //       name: 'Board Documents',
  //       icon: 'stat',
  //     },
  //     actions: ['portal/dispatchGetReports'],
  //   },
  // },
  {
    path: 'board-meetings',
    name: 'boardMeetings',
    component: PortalBoardMeetings,
    props: true,
    meta: {
      navConfig: {
        name: 'Board Meetings',
        icon: 'calendar',
      },
      actions: ['portal/dispatchGetMeetings'],
    },
    children: [
      {
        path: 'meeting',
        name: 'boardMeeting',
        component: PortalBoardMeetings,
        props: true,
      },
    ],
  },

  {
    path: 'board-resources',
    name: 'boardResources',
    component: PortalBoardResources,
    props: true,
    meta: {
      navConfig: {
        name: 'Board Resources',
        icon: 'stat',
      },
      actions: ['portal/dispatchGetLibraryFiles'],
    },
  },
  {
    path: 'portal-resources',
    name: 'portalResources',
    component: PortalResources,
    props: true,
    meta: {
      navConfig: {
        name: 'Portal Resources',
        icon: 'board',
      },
      actions: [],
    },
  },
  {
    path: 'help',
    name: 'portalHelp',
    component: PortalHelp,
    props: true,
    meta: {
      navConfig: {
        name: 'Help & Guides',
        icon: 'help',
      },
      actions: [],
    },
  },
  {
    path: 'settings',
    name: 'settings',
    component: PortalSettings,
    props: true,
    meta: {
      settings: true,
    },
    children: [
      {
        path: 'my-board',
        name: 'myBoard',
        props: true,
        component: PortalSettings,
        meta: {
          childComponent: 'SettingsMyBoard',
          isChild: true,
          actions: ['portal/dispatchGetMembers', 'portal/dispatchGetOwners'],
        },
      },
      {
        path: 'add-board-member',
        name: 'addBoardMember',
        props: true,
        component: PortalSettings,
        meta: {
          childComponent: 'SettingsAddBoardMember',
          isChild: true,
          actions: ['portal/dispatchGetMembers'],
        },
      },
    ],
  },
].map(mapRouteDisplayOrder)

const routes = [
  {
    path: '/',
    name: 'welcome',
    component: Welcome,
  },
  {
    path: '/signin',
    name: 'signIn',
    component: SignIn,
    props: (route) => ({
      emailQuery: route.query.email,
      resetPassword: route.query.resetPassword === 'true',
    }),
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPasswordValidation,
  },
  {
    path: '/portal/:id?',
    name: 'portal',
    component: Portal,
    props: true,
    children: routesPortalChildren,
    meta: { middlewareAuth: true },
    beforeEnter: (to, from, next) => {
      const schoolIdCurrent = Number.parseInt(to.params?.id)
      const schooldIdDefault = getSchool(to)?.id
      // invalid if 'id' is not a number or not one of this user's schools
      const invalidId =
        !Number.parseInt(schoolIdCurrent) ||
        schoolIdCurrent !== schooldIdDefault

      if (invalidId) {
        // if invalid, route to default school
        const id = schooldIdDefault
        next({ name: to.name, params: { id } })
      } else {
        next()
      }
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  window.Path = from.path

  /** Do not allow access to these routes if the user is not an administrator...  */
  if (to.matched.some((record) => record.meta.middlewareAuth)) {
    if (
      from.path !== '/signin' &&
      (from === START_LOCATION ||
        (!from.path.includes('/portal') && to.path.includes('/portal')) ||
        (from.path.includes('/admin') && to.path.includes('/portal')))
    ) {
      await auth.updateUser()
    }
    const isAuthenticated = auth.isAuthenticated()
    // Send to homepage if unauthenticated
    if (!isAuthenticated) {
      // not auth'd - redirect to sign in
      next({ path: '/signin' })
      return
    } else {
      // route has changed - make sure the current school is set correctly
      const school = getSchool(to)
      await store.dispatch('portal/dispatchSetSchool', school)

      // @TODO global loader and route actions
      // if (to.meta?.actions) {
      //   await store.dispatch('global/dispatchUpdateIsLoading', true)
      //   for (const action of to.meta.actions) {
      //     await store.dispatch(action)
      //   }
      //   await store.dispatch('global/dispatchUpdateIsLoading')
      // }
    }
  }

  next()
})

export default router
