<template>
  <Modal
    :trigger="MODAL_CONFIG.trigger"
    :title="MODAL_CONFIG.title"
    :description="MODAL_CONFIG.description"
    :content-classes="MODAL_CONFIG.contentClasses"
    :is-dialog-open="isModalOpen"
    @update-modal-state="updateIsModalOpen"
  >
    <template #trigger>
      <DialogTrigger>
        <a class="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
            />
          </svg>
          <div class="text-sm font-normal ml-4">
            {{ triggerText }}
          </div>
        </a>
      </DialogTrigger>
    </template>

    <template #content>
      <div class="pb-12 w-full bg-gray-primary h-full overflow-y-scroll">
        <div
          class="flex items-center justify-between bg-white pt-12 px-12 pb-4"
        >
          <h3>File Library</h3>
        </div>

        <div class="pt-12 mx-12">
          <!-- Tabs -->
          <template v-if="!isBoardResourcesContext">
            <div
              class="flex justify-between w-full rounded-lg overflow-hidden px-2"
            >
              <button
                :key="FILE_TABS[0].id"
                type="button"
                :title="FILE_TABS[0].title"
                class="py-3 text-sm px-6 md:px-10 flex justify-center items-center rounded-lg transition-all duration-100 ease-in-out"
                :class="[
                  isActiveFileTab(FILE_TABS[0].id)
                    ? 'border border-primary text-white bg-primary'
                    : 'bg-white',
                ]"
                @click="() => selectFileTab(FILE_TABS[0].id)"
              >
                <span>
                  {{ FILE_TABS[0].title }}
                </span>
              </button>
              <div
                class="flex bg-white w-[max-content] rounded-lg overflow-hidden"
              >
                <button
                  v-for="tab in FILE_TABS.slice(1)"
                  :key="tab.id"
                  type="button"
                  :title="tab.title"
                  class="py-3 text-sm px-6 md:px-10 flex justify-center items-center transition-all duration-100 ease-in-out"
                  :class="[
                    isActiveFileTab(tab.id)
                      ? 'border border-primary text-white bg-primary rounded-lg'
                      : 'bg-white',
                  ]"
                  @click="() => selectFileTab(tab.id)"
                >
                  <span>
                    {{ tab.title }}
                  </span>
                </button>
              </div>
            </div>
            <hr class="border-primary opacity-10 my-6" />
          </template>
          <!-- /Tabs -->

          <!-- Info -->
          <div
            class="flex items-center gap-4 p-4 mb-5 w-full bg-white rounded-md text-neutral-600 text-xs"
          >
            <div class="descriptionParent">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="w-5 h-5 text-tertiary"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-7-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9 9a.75.75 0 0 0 0 1.5h.253a.25.25 0 0 1 .244.304l-.459 2.066A1.75 1.75 0 0 0 10.747 15H11a.75.75 0 0 0 0-1.5h-.253a.25.25 0 0 1-.244-.304l.459-2.066A1.75 1.75 0 0 0 9.253 9H9Z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div>{{ descText }}</div>
          </div>
          <!-- /Info -->

          <!-- Tab content -->
          <div
            ref="tabContentParent"
            class="flex gap-4 flex-wrap lg:flex-nowrap w-full"
          >
            <div
              ref="tabContentMainParent"
              class="bg-white rounded-lg p-8 h-[max-content]"
              :class="[selectedFiles.length ? 'w-2/3' : 'w-full']"
            >
              <!-- Search -->
              <div
                v-if="canSearch"
                :key="activeFileTab.id"
                class="flex items-center w-full pb-2 mb-6 border-b border-truegray-100"
              >
                <FormKit
                  v-model="search"
                  type="text"
                  :placeholder="`Search ${activeFileTab.title}`"
                  inner-class="shadow-none"
                  @actions="false"
                />
              </div>
              <!-- /Search -->

              <!-- Editing uploaded file -->
              <div
                v-if="isEditingUploadedFile"
                class="flex items-center space-x-8 mt-4 pb-6 border-b border-truegray-100"
              >
                <div class="flex flex-col space-y-2">
                  <FormKit
                    :key="editedUploadedFile.id"
                    v-model="editedUploadedFile.title"
                    type="text"
                    :label="`Edit file: ${editedUploadedFile.originalTitle}`"
                  />
                  <FormKit
                    v-if="!isBoardResourcesTab"
                    v-model="editedUploadedFile.publicExcluded"
                    type="checkbox"
                    decorator-icon="check"
                    label="Public excluded"
                    outer-class="$reset mb-0 pl-1"
                  />
                </div>

                <div class="flex space-x-4 mt-2">
                  <button
                    type="button"
                    class="brand__button--trans-primary"
                    @click="resetEditedUploadedFile"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="brand__button--tertiary"
                    @click="onSubmitEditUploadedFile"
                  >
                    Save
                  </button>
                </div>
              </div>
              <!-- /Editing uploaded file -->

              <!-- Deleting uploaded file -->
              <div
                v-if="isDeletingUploadedFile"
                class="flex items-center space-x-8 mt-4 pb-6 border-b border-truegray-100"
              >
                <div>
                  Are you sure you want to delete
                  {{ deletedUploadedFile.title }}?
                </div>

                <div class="flex space-x-4">
                  <button
                    type="button"
                    class="brand__button--trans-primary"
                    @click="resetDeletedUploadedFile"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="brand__button bg-red-400 border border-red-400 text-white hover:bg-white hover:text-red-400 text-white"
                    @click="onSubmitDeleteUploadedFile"
                  >
                    Confirm
                  </button>
                </div>
              </div>
              <!-- /Deleting uploaded file -->

              <!-- Active tab: New Files -->
              <div v-if="isActiveFileTab('new') && isUploadingLinks" class="">
                <FormKit
                  id="uploadLinkForm"
                  ref="uploadLinkInputParent"
                  v-model="uploadLinkForm"
                  type="form"
                  :actions="false"
                  @submit="onSubmitUploadLink"
                >
                  <FormKit name="title" type="text" label="Link title" />
                  <FormKit name="video_url" type="text" label="Link URL" />
                  <FormKit
                    name="public_excluded"
                    type="checkbox"
                    decorator-icon="check"
                    label="Public excluded"
                  />
                  <div class="flex space-x-4 items-center">
                    <FormKit type="submit" label="Upload and select" />
                    <button
                      type="button"
                      class="brand__button--trans-primary mb-8"
                      @click="toggleLinkUpload"
                    >
                      Cancel
                    </button>
                  </div>
                </FormKit>
              </div>
              <div v-if="isActiveFileTab('new') && !isUploadingLinks">
                <button
                  type="button"
                  class="brand__button--trans-primary mb-8"
                  @click="toggleLinkUpload"
                >
                  Upload external link
                </button>
                <FormKit
                  id="uploadFilesForm"
                  ref="uploadFilesInputParent"
                  v-model="uploadFilesForm"
                  type="form"
                  :actions="false"
                  @submit="onSubmitUploadFiles"
                >
                  <FormKit
                    type="file"
                    :label="uploadFilesInputLabel"
                    name="filesToUpload"
                    multiple="true"
                    help="Use shift/cmd/ctrl while selecting to select multiple, or drag and drop"
                  />
                  <div
                    v-if="
                      !isUploadingFiles &&
                      uploadFilesForm?.filesToUpload?.length
                    "
                    ref="uploadFilesListParent"
                    class="flex flex-col divide-y divide-truegray-100 mb-8 pr-4 w-[max-content]"
                  >
                    <div
                      v-for="(file, index) in uploadFilesForm.filesToUpload"
                      :key="index"
                      class="flex justify-between items-center py-3"
                    >
                      <div class="flex space-x-4 items-center pr-12">
                        <div
                          title="Edit file name"
                          class="w-6 h-6 rounded-lg flex items-center justify-center cursor-pointer hover:bg-primary hover:text-white text-primary"
                          @click="() => editFile(index)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            class="w-4 h-4"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M11.013 2.513a1.75 1.75 0 0 1 2.475 2.474L6.226 12.25a2.751 2.751 0 0 1-.892.596l-2.047.848a.75.75 0 0 1-.98-.98l.848-2.047a2.75 2.75 0 0 1 .596-.892l7.262-7.261Z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </div>

                        <p class="m-0">{{ file.name }}</p>
                      </div>

                      <FormKit
                        v-if="!isBoardResourcesContext"
                        v-model="
                          uploadFilesForm.filesToUpload[index].publicExcluded
                        "
                        type="checkbox"
                        decorator-icon="check"
                        label="Public excluded"
                        outer-class="$reset mb-0 pl-1"
                      />
                    </div>

                    <div v-if="isEditingFile" class="flex space-x-4 mt-8 pt-2">
                      <FormKit
                        v-model="editedFile.file.name"
                        type="text"
                        label="File name"
                      />
                      <button
                        type="button"
                        class="mt-2"
                        @click="onSubmitEditFile"
                      >
                        Save
                      </button>
                    </div>
                  </div>

                  <FormKit type="submit" :label="uploadFilesSubmitLabel" />
                </FormKit>
              </div>
              <!-- /Active tab: New Files -->

              <!-- Active tab: NOT New Files -->
              <div
                v-else
                class="divide-y divide-truegray-100 max-h-[320px] overflow-y-scroll"
              >
                <!-- No files found -->
                <div
                  v-if="!filteredFiles?.length && !isUploadingLinks"
                  class="py-3"
                >
                  <p class="brand__text--info italic mb-0">No files found...</p>
                </div>
                <!-- No files found -->

                <!-- Meetings tab content -->
                <template v-else-if="isActiveFileTab('meeting')">
                  <div
                    v-for="(meeting, index) in filteredFiles"
                    :key="index"
                    ref="meetingParent"
                    class="flex flex-col mb-4"
                    :class="{ 'pt-4': index !== 0 }"
                  >
                    <div class="flex">
                      <p class="font-bold text-primary text-sm p-0 mb-1">
                        {{ meeting.title }}
                      </p>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        class="w-4 h-4 ml-4 cursor-pointer"
                        @click="() => toggleMeetingTabSectionExpanded(index)"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm.75-10.25v2.5h2.5a.75.75 0 0 1 0 1.5h-2.5v2.5a.75.75 0 0 1-1.5 0v-2.5h-2.5a.75.75 0 0 1 0-1.5h2.5v-2.5a.75.75 0 0 1 1.5 0Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p class="text-xs text-truegray-400 italic m-0">
                      {{ meeting.date }}
                    </p>
                    <div
                      :class="
                        meetingTabSectionsExpanded[index]
                          ? 'opacity-100'
                          : 'opacity-0 h-0 pointer-events-none'
                      "
                      class="ml-6"
                    >
                      <div
                        v-for="file in meeting.files"
                        :key="file.id"
                        class="flex items-center pb-1 pt-3"
                      >
                        <FormKit
                          :key="selectedFileIds.join(',')"
                          type="checkbox"
                          :value="selectedFileIds.includes(file.id)"
                          decorator-icon="check"
                          :label="file.title"
                          outer-class="$reset mb-0 grow-0"
                          :label-class="getFileLabelClass(file.id)"
                          @input="() => toggleSelectedFile(file.id)"
                        />
                        <svg
                          v-if="file.video_url"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                          fill="currentColor"
                          class="w-3 h-3 ml-2 text-truegray-500"
                          title="External Link"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8.914 6.025a.75.75 0 0 1 1.06 0 3.5 3.5 0 0 1 0 4.95l-2 2a3.5 3.5 0 0 1-5.396-4.402.75.75 0 0 1 1.251.827 2 2 0 0 0 3.085 2.514l2-2a2 2 0 0 0 0-2.828.75.75 0 0 1 0-1.06Z"
                            clip-rule="evenodd"
                          />
                          <path
                            fill-rule="evenodd"
                            d="M7.086 9.975a.75.75 0 0 1-1.06 0 3.5 3.5 0 0 1 0-4.95l2-2a3.5 3.5 0 0 1 5.396 4.402.75.75 0 0 1-1.251-.827 2 2 0 0 0-3.085-2.514l-2 2a2 2 0 0 0 0 2.828.75.75 0 0 1 0 1.06Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </template>
                <!-- /Meetings tab content -->

                <!-- Tab content -->
                <template v-else>
                  <div
                    v-for="file in filteredFiles"
                    :key="file.id"
                    class="flex items-center justify-between group"
                  >
                    <div
                      :key="selectedFileIds.join(',')"
                      class="flex flex-col py-3"
                    >
                      <div class="flex items-center">
                        <FormKit
                          :key="file.id"
                          type="checkbox"
                          :value="selectedFileIds.includes(file.id)"
                          decorator-icon="check"
                          :label="file.title"
                          outer-class="$reset mb-0 grow-0"
                          :label-class="getFileLabelClass(file.id)"
                          :disabled="isModifyingUploadedFile"
                          @input="() => toggleSelectedFile(file.id)"
                        />

                        <svg
                          v-if="file.video_url"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                          fill="currentColor"
                          class="w-3 h-3 ml-2 text-truegray-500"
                          title="External Link"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8.914 6.025a.75.75 0 0 1 1.06 0 3.5 3.5 0 0 1 0 4.95l-2 2a3.5 3.5 0 0 1-5.396-4.402.75.75 0 0 1 1.251.827 2 2 0 0 0 3.085 2.514l2-2a2 2 0 0 0 0-2.828.75.75 0 0 1 0-1.06Z"
                            clip-rule="evenodd"
                          />
                          <path
                            fill-rule="evenodd"
                            d="M7.086 9.975a.75.75 0 0 1-1.06 0 3.5 3.5 0 0 1 0-4.95l2-2a3.5 3.5 0 0 1 5.396 4.402.75.75 0 0 1-1.251-.827 2 2 0 0 0-3.085-2.514l-2 2a2 2 0 0 0 0 2.828.75.75 0 0 1 0 1.06Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <p class="text-xs text-truegray-400 ml-10 mb-0">
                        {{ file.created_at }}
                      </p>
                    </div>

                    <div
                      v-show="!isModifyingUploadedFile"
                      class="flex space-x-2 opacity-0 group-hover:opacity-100 transition-all"
                    >
                      <div
                        title="Edit file"
                        class="rounded-xl text-black hover:text-white cursor-pointer w-8 h-8 bg-white hover:bg-primary opacity-100 hover:opacity-60 flex items-center justify-center"
                        @click="() => editUploadedFile(file.id)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z"
                          />
                          <path
                            d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z"
                          />
                        </svg>
                      </div>
                      <div
                        title="Delete file"
                        class="rounded-xl text-red-400 hover:text-white transition-all cursor-pointer w-8 h-8 bg-white hover:bg-red-400 opacity-100 hover:opacity-30 flex items-center justify-center"
                        @click="() => deleteUploadedFile(file.id)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </template>
                <!-- /Tab content -->
              </div>
              <!-- Active tab: NOT New Files -->
            </div>

            <!-- Selected files -->
            <div
              v-if="!isBoardResourcesContext"
              ref="selectedFilesListParent"
              class="w-1/3 bg-white rounded-lg p-8 divide-y divide-truegray-100 mt-12 lg:mt-0 h-[max-content]"
            >
              <h5 class="text-h6">Selected files:</h5>
              <template v-if="selectedFiles.length">
                <div
                  v-for="file in selectedFiles"
                  :key="file.id"
                  class="flex items-center justify-between group"
                >
                  <div class="flex items-center py-2">
                    <p class="text-sm m-0">
                      {{ file.title }}
                    </p>

                    <p
                      v-if="isNewFile(file.id)"
                      class="text-sm m-0 ml-2 italic"
                    >
                      (new)
                    </p>
                    <svg
                      v-if="file.video_url"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      class="w-3 h-3 ml-2 text-truegray-500"
                      title="External Link"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8.914 6.025a.75.75 0 0 1 1.06 0 3.5 3.5 0 0 1 0 4.95l-2 2a3.5 3.5 0 0 1-5.396-4.402.75.75 0 0 1 1.251.827 2 2 0 0 0 3.085 2.514l2-2a2 2 0 0 0 0-2.828.75.75 0 0 1 0-1.06Z"
                        clip-rule="evenodd"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M7.086 9.975a.75.75 0 0 1-1.06 0 3.5 3.5 0 0 1 0-4.95l2-2a3.5 3.5 0 0 1 5.396 4.402.75.75 0 0 1-1.251-.827 2 2 0 0 0-3.085-2.514l-2 2a2 2 0 0 0 0 2.828.75.75 0 0 1 0 1.06Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <div
                      title="remove"
                      class="flex items-center justify-center text-red-400 hover:text-red-500 cursor-pointer ml-6"
                      @click="() => toggleSelectedFile(file.id)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM8.28 7.22a.75.75 0 0 0-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 1 0 1.06 1.06L10 11.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L11.06 10l1.72-1.72a.75.75 0 0 0-1.06-1.06L10 8.94 8.28 7.22Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </template>
              <div v-else>
                <p class="brand__text--info italic mb-0 pt-4">
                  No files attached.
                </p>
              </div>
              <button
                type="button"
                class="brand__button--primary mt-6"
                :class="
                  canSubmit
                    ? 'opacity-100 cursor-pointer'
                    : 'opacity-60 cursor-not-allowed'
                "
                @click="onSubmitAttachFiles"
              >
                {{ submitButtonText }}
              </button>
            </div>
            <!-- /Selected files -->
          </div>
          <!-- /Tab content -->
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { defineComponent, ref, reactive, computed, watch } from 'vue'
import { DialogTrigger } from 'radix-vue'
import { useStore } from 'vuex'
import { useAutoAnimate } from '@formkit/auto-animate/vue'
import { reset } from '@formkit/core'
import Modal from './Modal.vue'
import { pluraliseNoun } from '../../store/util.js'

export default defineComponent({
  name: 'ModalFileLibrary',

  components: {
    Modal,
    DialogTrigger,
  },

  props: {
    isBoardResourcesContext: {
      type: Boolean,
      default: false,
    },

    attachedFileIds: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['update:selectedFiles'],

  setup(props, { emit }) {
    const store = useStore()

    // #region Animations config
    const [descriptionParent] = useAutoAnimate()
    const [tabContentParent] = useAutoAnimate()
    const [tabContentMainParent] = useAutoAnimate()
    const [selectedFilesListParent] = useAutoAnimate()
    const [uploadFilesInputParent] = useAutoAnimate()
    const [uploadFilesListParent] = useAutoAnimate()
    const [meetingParent] = useAutoAnimate()
    // #endregion

    // #region Modal config/state
    const MODAL_CONFIG = {
      trigger: {
        text: '',
        as: 'a',
        classes: '',
      },

      title: {
        text: 'File Library',
        classes: 'hidden',
      },

      description: {
        text: 'Manage all of your board files to use throughout your portal',
        classes: 'hidden',
      },

      contentClasses:
        'w-[70vw] h-[80vh] bg-truegray-100 overflow-hidden modal-content',
    }

    const triggerText = computed(() => {
      if (props.isBoardResourcesContext) {
        return 'Add Resources'
      }

      return 'Open File Library'
    })

    const isModalOpen = ref(false)
    const updateIsModalOpen = (val) => {
      isModalOpen.value = val
    }
    // #endregion

    // #region Tabs
    const DESC_TEXT_BOARD_RESOURCES =
      'Select one or multiple files to be uploaded as Board Resources. \
      You can edit their file names before uploading.'
    const DESC_TEXT =
      'You can attach any of these files to your current meeting by checking the box. \
      You can remove any files that are already attached to the current meeting by unchecking the box. \
      Click the "Attach" button to save your updates.'
    const FILE_TABS = [
      {
        id: 'new',
        title: 'Add New Files',
        desc: `Brand new files need to be uploaded before they are attached to a meeting.
              Files you upload here will be automatically selected for this meeting - \
              after uploading, just click the "Attach" button to save your updates.`,
      },
      {
        id: 'meeting',
        title: 'Previous Meeting Files',
        desc: `These are files already attached to a previous or upcoming meeting. \
              ${DESC_TEXT}`,

        getter: 'portal/getLibraryMeetingFiles',
        action: 'portal/dispatchGetLibraryMeetingFiles',
      },
      {
        id: 'resource',
        title: 'Board Resources',
        desc: `These are files that have been uploaded as Board Resources. \
              ${DESC_TEXT}`,

        getter: 'portal/getLibraryResourceFiles',
        action: 'portal/dispatchGetLibraryResourceFiles',
      },
      {
        id: 'misc',
        title: 'Other Files',
        desc: `These are files that have already been uploaded but are not yet attached to a meeting.  \
              They may have been uploaded in error. You can delete any files that are no longer needed,
              or attach any of these files to your current meeting by checking the box.`,

        getter: 'portal/getLibraryMiscFiles',
        action: 'portal/dispatchGetLibraryMiscFiles',
      },
    ]
    const descText = computed(() =>
      props.isBoardResourcesContext
        ? DESC_TEXT_BOARD_RESOURCES
        : activeFileTab.value?.desc,
    )
    const activeFileTab = ref(FILE_TABS[0])

    const isBoardResourcesTab = computed(
      () => activeFileTab.value?.id === 'resource',
    )

    const isActiveFileTab = (id) => {
      return activeFileTab.value.id === id
    }

    const setActiveFileTab = (id) => {
      activeFileTab.value = FILE_TABS.find((tab) => tab.id === id)
    }

    const selectFileTab = async (id) => {
      setActiveFileTab(id)
      await getFiles()
    }
    // #endregion

    // #region New Files tab (upload form)
    const isUploadingLinks = ref(false)
    const uploadLinkForm = ref({})
    const toggleLinkUpload = () => {
      isUploadingLinks.value = !isUploadingLinks.value
    }

    const onSubmitUploadLink = async (data) => {
      const uploadedFileIds = await store.dispatch(
        'portal/dispatchUploadLink',
        data,
      )
      selectedFileIds.value = [...selectedFileIds.value, ...uploadedFileIds]
      newFileIds.value = [...newFileIds.value, ...uploadedFileIds]
      resetUploadLinkForm()
    }

    const resetUploadLinkForm = () => {
      isUploadingLinks.value = false
      uploadLinkForm.value = {}
    }

    const uploadFilesForm = ref({})
    const isUploadingFiles = ref(false)
    const filesToUpload = computed(() => uploadFilesForm?.value?.filesToUpload)
    const canUploadFiles = computed(
      () => !isUploadingFiles.value && filesToUpload.value?.length,
    )
    const uploadFilesInputLabel = computed(() => {
      return props.isBoardResourcesContext ? 'Select files' : 'Attach files'
    })
    const uploadFilesSubmitLabel = computed(() => {
      if (props.isBoardResourcesContext) {
        return pluraliseNoun(
          'Upload board resource',
          uploadFilesForm?.value?.filesToUpload?.length || 1,
        )
      }

      return 'Upload and select'
    })

    const onSubmitUploadFiles = async (data) => {
      if (canUploadFiles.value) {
        isUploadingFiles.value = true

        const formData = new FormData()
        data.filesToUpload.forEach((file, index) => {
          formData.append(`files[${index}]`, file.file)
          formData.append(`names[${index}]`, file.name)
          formData.append(`publicExcluded[${index}]`, !!file.publicExcluded)
          formData.append(
            `isBoardResource[${index}]`,
            !!props.isBoardResourcesContext,
          )
        })

        const uploadedFileIds = await store.dispatch(
          'portal/dispatchUploadFiles',
          formData,
        )

        if (!props.isBoardResourcesContext) {
          selectedFileIds.value = [...selectedFileIds.value, ...uploadedFileIds]
          newFileIds.value = [...newFileIds.value, ...uploadedFileIds]
        }

        reset('uploadFilesForm')
        isUploadingFiles.value = false

        if (props.isBoardResourcesContext) {
          isModalOpen.value = false
        }
      }
    }
    // #endregion

    // #region Meeting Files tab
    const meetingTabSectionsExpanded = reactive({})

    const toggleMeetingTabSectionExpanded = (index) => {
      meetingTabSectionsExpanded[index] = !meetingTabSectionsExpanded[index]
    }

    const resetMeetingTabSectionsExpanded = () => {
      Object.keys(meetingTabSectionsExpanded).forEach((key) => {
        delete meetingTabSectionsExpanded[key]
      })
    }
    // #endregion

    // #region Files
    const files = computed(() => store.getters[activeFileTab.value.getter])
    const filteredFiles = computed(() => {
      const fileMatches = (file, search) =>
        file.title.toLowerCase().includes(search)

      if (search.value) {
        const searchLower = search.value.toLowerCase()

        if (activeFileTab.value?.id === 'meeting') {
          // @TODO Portal 2.0 - For meetings, files are actually an array of meetings with
          // files attached, so we have to search their nested arrays. We could definitely
          // improve/standardise data structure so we don't have to do stuff like this!
          return files.value
            .map((meeting) => {
              const matchingFiles = meeting.files.filter((file) =>
                file.title.toLowerCase().includes(searchLower),
              )

              return matchingFiles?.length
                ? { ...meeting, files: matchingFiles }
                : null
            })
            .filter((meeting) => !!meeting)
        } else {
          return files.value.filter((file) => fileMatches(file, searchLower))
        }
      }

      return files.value
    })

    const isModifyingUploadedFile = computed(
      () => isEditingUploadedFile.value || isDeletingUploadedFile.value,
    )

    const submitButtonText = computed(() => {
      // If we currently have files selected, we are 'attaching'
      if (selectedFileIds.value.length) {
        return 'Attach'
      }

      // If we currently do NOT have files selected but
      // we used to, we are 'detaching'
      if (hasUpdatedFiles.value) {
        return 'Detach'
      }

      // If we currently do NOT have files selected and
      // we never did, we are 'attaching'
      return 'Attach'
    })

    const canSubmit = computed(() => hasUpdatedFiles.value)

    const getFileLabelClass = (fileId) => {
      const base = '$reset ml-3 text-sm'

      if (isUploadedFileBeingDeleted(fileId)) {
        return `${base} text-red-200 font-bold`
      } else if (isUploadedFileBeingEdited(fileId)) {
        return `${base} text-tertiary font-bold`
      } else {
        return `${base} text-primary`
      }
    }

    const getFiles = async ({
      page = 1,
      // filters = store.getters['connections/getFilterFormPayload'],
    } = {}) => {
      if (activeFileTab.value?.action) {
        await store.dispatch(activeFileTab.value.action, {
          page,
        })
      } else {
        await store.dispatch('portal/dispatchGetLibraryFiles')
      }
    }

    const onSubmitAttachFiles = () => {
      if (canSubmit.value) {
        emit('update:selectedFiles', selectedFiles.value)
        isModalOpen.value = false
      }
    }
    // #endregion

    // #region Editing file - pre-upload
    const editedFile = reactive({ index: null, file: null })
    const isEditingFile = computed(() => editedFile?.file)

    const resetEditedFile = () => {
      editedFile.index = null
      editedFile.file = null
    }

    const editFile = (index) => {
      editedFile.index = index
      editedFile.file = { ...uploadFilesForm.value.filesToUpload[index] }
    }

    const onSubmitEditFile = () => {
      uploadFilesForm.value.filesToUpload[editedFile.index].name =
        editedFile.file.name
      resetEditedFile()
    }
    // #endregion

    // #region Editing file - post-upload
    const editedUploadedFile = ref(null)
    const isEditingUploadedFile = computed(() => !!editedUploadedFile.value)

    const isUploadedFileBeingEdited = (fileId) => {
      return editedUploadedFile.value?.id === fileId
    }

    const resetEditedUploadedFile = () => {
      editedUploadedFile.value = null
    }

    const editUploadedFile = (fileId) => {
      const originalFile = files.value.find((file) => file.id === fileId)
      editedUploadedFile.value = {
        originalTitle: originalFile.title,
        ...originalFile,
        publicExcluded: !!originalFile.public_excluded,
      }
    }

    const onSubmitEditUploadedFile = async () => {
      const payload = {
        id: editedUploadedFile.value.id,
        publicExcluded: editedUploadedFile.value.publicExcluded,
        title: editedUploadedFile.value.title,
        libraryType: activeFileTab.value.id?.toUpperCase(),
      }

      const success = await store.dispatch('portal/dispatchEditFile', payload)
      if (success) {
        resetEditedUploadedFile()
      }
    }
    // #endregion

    // #region Deleting file - post-upload
    const deletedUploadedFileId = ref(null)
    const isDeletingUploadedFile = computed(() => !!deletedUploadedFile.value)

    const deletedUploadedFile = computed(() => {
      if (deletedUploadedFileId.value) {
        return files.value.find(
          (file) => file.id === deletedUploadedFileId.value,
        )
      }

      return null
    })

    const isUploadedFileBeingDeleted = (fileId) => {
      return deletedUploadedFileId.value === fileId
    }

    const resetDeletedUploadedFile = () => {
      deletedUploadedFileId.value = null
    }

    const deleteUploadedFile = (fileId) => {
      deletedUploadedFileId.value = fileId
    }

    const onSubmitDeleteUploadedFile = async () => {
      const payload = {
        id: deletedUploadedFileId.value,
        libraryType: activeFileTab.value.id?.toUpperCase(),
      }

      const success = await store.dispatch('portal/dispatchDeleteFile', payload)
      if (success) {
        resetDeletedUploadedFile()
      }
    }
    // #endregion

    // #region Search
    const search = ref(null)
    const canSearch = computed(() => {
      return !isActiveFileTab('new')
    })

    const resetSearch = () => {
      search.value = null
    }
    // #endregion

    // #region Selected files
    const selectedFileIdsOriginal = ref([])
    const selectedFileIds = ref([])
    const selectedFiles = computed(() => {
      return store.getters['portal/getLibraryFilesById'](selectedFileIds.value)
    })

    const hasUpdatedFiles = computed(() => {
      return (
        selectedFileIdsOriginal.value.length !== selectedFileIds.value.length ||
        selectedFileIdsOriginal.value.some(
          (id, index) => id !== selectedFileIds.value[index],
        )
      )
    })

    const toggleSelectedFile = (fileId) => {
      const index = selectedFileIds.value.indexOf(fileId)
      if (index === -1) {
        selectedFileIds.value.push(fileId)
      } else {
        selectedFileIds.value.splice(index, 1)
      }
    }

    const newFileIds = ref([])
    const isNewFile = (id) => {
      return newFileIds.value.includes(id)
    }
    const resetNewFileIds = () => {
      newFileIds.value = []
    }

    /**
     * Clear the selected file IDs and re-init them with
     * current state (passed from props).
     */
    const resetSelectedFileIds = () => {
      selectedFileIdsOriginal.value.splice(
        0,
        selectedFileIdsOriginal.value.length,
      )
      selectedFileIds.value.splice(0, selectedFileIds.value.length)

      selectedFileIdsOriginal.value = [...props.attachedFileIds]
      selectedFileIds.value = [...selectedFileIdsOriginal.value]
    }
    // #endregion

    // #region Init
    /**
     * init
     *
     * Reset everything! All forms and state.
     * Refresh files data.
     */
    const init = async () => {
      uploadFilesForm.value = {}
      activeFileTab.value = FILE_TABS[0]
      isUploadingFiles.value = false
      resetUploadLinkForm()
      resetMeetingTabSectionsExpanded()
      resetEditedFile()
      resetEditedUploadedFile()
      resetDeletedUploadedFile()
      resetSearch()
      resetSelectedFileIds()
      resetNewFileIds()
      await getFiles()
    }

    watch(isModalOpen, async () => {
      await init()
    })
    // #endregion

    return {
      // Animations config
      descriptionParent,
      tabContentParent,
      tabContentMainParent,
      selectedFilesListParent,
      uploadFilesInputParent,
      uploadFilesListParent,
      meetingParent,

      // Modal config/state
      MODAL_CONFIG,
      triggerText,
      isModalOpen,
      updateIsModalOpen,

      // Tabs
      FILE_TABS,
      descText,
      activeFileTab,
      isBoardResourcesTab,
      isActiveFileTab,
      setActiveFileTab,
      selectFileTab,

      // New Files tab
      uploadLinkForm,
      toggleLinkUpload,
      isUploadingLinks,
      onSubmitUploadLink,
      resetUploadLinkForm,

      uploadFilesForm,
      isUploadingFiles,
      uploadFilesInputLabel,
      uploadFilesSubmitLabel,
      onSubmitUploadFiles,

      // Meeting Files tab
      meetingTabSectionsExpanded,
      toggleMeetingTabSectionExpanded,

      // Files
      files,
      filteredFiles,
      isModifyingUploadedFile,
      canSubmit,
      submitButtonText,
      getFileLabelClass,
      onSubmitAttachFiles,

      // Editing file - pre-upload
      editedFile,
      isEditingFile,
      editFile,
      onSubmitEditFile,

      // Editing file - post-upload
      editedUploadedFile,
      isEditingUploadedFile,
      isUploadedFileBeingEdited,
      resetEditedUploadedFile,
      editUploadedFile,
      onSubmitEditUploadedFile,

      // Deleting file - post-upload
      deletedUploadedFile,
      isDeletingUploadedFile,
      isUploadedFileBeingDeleted,
      resetDeletedUploadedFile,
      deleteUploadedFile,
      onSubmitDeleteUploadedFile,

      // Search
      search,
      canSearch,

      // Selected files
      selectedFileIds,
      selectedFiles,
      toggleSelectedFile,
      newFileIds,
      isNewFile,
    }
  },
})
</script>

<style scoped>
.group .peer li {
  display: none !important;
}
</style>

<style>
.modal-content {
  padding: 0 !important;
}
</style>
