<template>
  <main class="bg-gray-primary">
    <PageHeader title="Portal Resources" />

    <PageContent>
      <template #default>
        <div class="w-full">
          <h5 class="mb-8">Articles</h5>
          <div
            class="mx-auto grid grid-cols-1 sm:grid-cols-2 gap-6 sm:mx-0 lg:max-w-none xl:grid-cols-3"
          >
            <Card v-for="(card, index) in cards" :key="index" :data="card" />
          </div>
        </div>
      </template>
    </PageContent>
  </main>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { Vue3Lottie } from 'vue3-lottie'
import { ANIMATION_LOADING } from '../../constants.js'
import PageHeader from '../../components/PageHeader.vue'
import PageContent from '../../components/PageContent.vue'
import Card from '../../components/Card.vue'

export default defineComponent({
  name: 'PortalResources',

  components: {
    Vue3Lottie,
    PageHeader,
    PageContent,
    Card,
  },

  setup() {
    const store = useStore()
    const isAdmin = computed(() => store.getters['auth/isAdmin'])
    const isLoading = computed(() => store.getters['global/isLoading'])

    const route = useRoute()

    const cards = [
      {
        title: 'Board Succession Planning',
        href: 'https://hail.to/schooled-nz/article/eXFu2gr',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 2, 2025',
        image: '/img/portal/articles/succession-planning.jpg',
      },
      {
        title: 'What is a School Board?',
        href: 'https://hail.to/schooled-nz/article/WG3Ahf4',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 2, 2025',
        image: '/img/portal/articles/school-board.jpg',
      },
      {
        title: 'The Role of a School Board',
        href: 'https://hail.to/schooled-nz/article/Bel5c7Y',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 2, 2025',
        image: '/img/portal/articles/role-of-the-board.jpg',
      },
      {
        title: 'Policies, Processes & Bylaws',
        href: 'https://hail.to/schooled-nz/article/oBYcLY7',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 2, 2025',
        image: '/img/portal/articles/policies.jpg',
      },
      {
        title: 'The Board Secretary',
        href: 'https://hail.to/schooled-nz/article/VKEXP6V',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 2, 2025',
        image: '/img/portal/articles/secretary.jpg',
      },
      {
        title: 'The Role of the Presiding Member',
        href: 'https://hail.to/schooled-nz/article/H96Yxct',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 2, 2025',
        image: '/img/portal/articles/presiding-member.jpg',
      },
    ]

    return {
      ANIMATION_LOADING,
      isLoading,
      isAdmin,
      route,
      cards,
    }
  },
})
</script>
